import {ChartData, DefaultApi} from './generated-src';
import {getAppSetting} from '../config/AppSettings';
import {Configuration} from "./generated-src";
import {splitArray} from "../utils/arrayUtils";


export class LiveMonitoringApi extends DefaultApi {
    public async batchGetCharts(ids, resolution, startTime, endtime) {
        const promises: Array<Promise<Array<ChartData>>> =  []
        splitArray(ids, 8).forEach(batch => {
            promises.push(super.getCharts(batch, resolution, startTime, endtime)
                .then(resp => {
                    return resp.data
                }))
        })

        return Promise.all(promises)
            .then((responses) => {
                const results: ChartData[] = []
                responses.forEach(resp => results.push(...resp))
                return {
                    data: results
                }
            })

    }



}

export default function () {
    const apiBasePath = `https://${getAppSetting('apiUrl')}/jwt`;
    return new LiveMonitoringApi(new Configuration({basePath: apiBasePath}));
}