import SearchContainer, {SearchContainerProps} from "../search/SearchContainer";
import {Cards, ExpandableSection} from "@amzn/awsui-components-react";
import * as React from "react";
import {useEffect, useMemo} from "react";
import LiveMonitoringApi from "../../live-monitoring/LiveMonitoringApi";
import {ChartData} from "../../live-monitoring/generated-src";
import uPlot from "uplot";
import {DualExpandableChartProps, StaticDualExpandableChartProps} from "./DualExpandableChartContainer";
import {ExpandableChartContainerProps, StaticExpandableChartContainerProps} from "./ExpandableChartContainer";
import {FlatChartContainerProps, StaticFlatChartContainerProps} from "./FlatChartContainer";
import {ProfileContext} from "../../context/ForecastProfileContext";
import {sortByExplicitRegionOrder} from "../../utils/sortUtils";

export interface ChartCollectionProps {
    initialActiveProfiles: string[],
    containerDefinition: (
        ((DualExpandableChartProps) => JSX.Element) |
        ((ExpandableChartProps) => JSX.Element) |
        ((FlatChartContainerProps) => JSX.Element)),
    staticContainerProps: StaticDualExpandableChartProps[] | StaticExpandableChartContainerProps[] | StaticFlatChartContainerProps[],
    addDynamicProfileSetting: boolean,
    searchContainerProps?: SearchContainerProps
    cardsPerRow?: number
}


export interface CollectionCardProps {
    chartDataMap: Map<string, ChartData>
}

export interface ActionableCollectionCardProps {
    addActiveCharts: (profileIds: string[]) => void,
    removeCharts: (profileIds: string[]) => void,
    sortByExplicitRegion: (profileIds: string[]) => void
}

export interface DateRange {
    currentStart: Date
    currentEnd: Date
    minStart: Date
    maxEnd: Date
}


export default function ChartCollection(props: ChartCollectionProps) {
    const api = LiveMonitoringApi();

    const [dateRange, setDateRange] = React.useState<DateRange>(initDateRange)
    const resolutionRef = React.useRef(determineResolution(dateRange.currentStart, dateRange.currentEnd));
    const [activeChartData, setActiveChartData] = React.useState<Map<string, ChartData>>(new Map())
    const [dataLoading, setDataLoading] = React.useState<Boolean>(true);
    const mouseAction = React.useRef({
        clickDown: false,
        moved: false,
        dateOfMouseDown: dateRange.minStart
    }
)
    const profileToRegionMapping = new Map(React.useContext(ProfileContext).map(
        profile => [profile.forecastProfileId, profile.region]))

    const chartSync = useMemo(() => uPlot.sync("chartSync"), []);


    const addChartsToMap = (profileIds, resolution, startTime, endTime) => {
        const startOfFirstDay = new Date(startTime.toDateString())
        var dayAfterLastday = new Date(endTime.toDateString())
        dayAfterLastday.setDate(dayAfterLastday.getDate()+1)
        api.batchGetCharts(profileIds, resolution, startOfFirstDay.toISOString(), dayAfterLastday.toISOString())
            .then(results => {
                return results.data
            })
            .then(data => {
                setActiveChartData(prevState => {
                    const updatedState = new Map(prevState)
                    data.forEach(
                        chartData => {
                            updatedState.set(chartData['forecastProfileId'], chartData)
                        })
                    return updatedState
                })
            })
    }

    const triggerChartCallOnScaleChange = (
        resetToDefault: boolean,
        startTime?: Date,
        endTime?: Date
    ) => {
        const activeProfiles = [...activeChartData.keys()]

        if (resetToDefault) {
            startTime = dateRange.minStart
            endTime = dateRange.maxEnd
        }
        if (startTime === undefined || endTime === undefined) {
            throw new Error("Start time and end time need to be populated")
        }
        const newResolution = determineResolution(startTime, endTime)
        if (newResolution !== resolutionRef.current) {
            addChartsToMap(activeProfiles, newResolution, startTime, endTime)
            setDataLoading(true);
            resolutionRef.current = newResolution;
        }
        setDateRange(prevState => {
            return {
                ...prevState,
                currentStart: startTime as Date,
                currentEnd: endTime as Date
            }
        })
    }
    const removeCharts = (profileIdsToRemove: string[]) => {
        setActiveChartData(mappedData => {
            const updatedMap = new Map(mappedData);
            profileIdsToRemove.forEach(
                profileIds => {
                    updatedMap.delete(profileIds)
                }
            )
            return updatedMap;
        })
    }

    //init / change to props
    useEffect(() => {
            addChartsToMap(
                props.initialActiveProfiles,
                resolutionRef.current,
                dateRange.currentStart,
                dateRange.currentEnd)
        },
        [props.initialActiveProfiles])

    //Update actuals
    useEffect(() => {
        const activeProfiles = [...activeChartData.keys()]
        const queryActuals = (chartStart) => {
            const actualsStart = new Date()
            if (chartStart > actualsStart || !activeProfiles.length || mouseAction.current.clickDown){
                return;
            }
            actualsStart.setHours(actualsStart.getHours() - 2)
            api.getActuals(activeProfiles, resolutionRef.current, actualsStart.toISOString())
                .then(results => {
                    setActiveChartData(prevState => {
                        const updatedState = new Map(prevState)
                        results.data.forEach(actuals => {
                                const chartData = updatedState.get(actuals.forecastProfileId)
                                if (chartData && chartData.datapoints) {
                                    //merge timeseries
                                    let dateTimeValues = chartData.datapoints.dateDatapoints
                                    let actualValues = chartData.datapoints.aDatapoints

                                    const receivedDates = actuals.datapointList.d
                                    const receivedValues = actuals.datapointList.v

                                    const firstIdx = dateTimeValues?.findIndex(val => val === receivedDates[0])
                                    if (firstIdx !== -1) {
                                        dateTimeValues.splice(firstIdx, receivedDates.length, ...receivedDates)
                                        actualValues.splice(firstIdx, receivedValues.length, ...receivedValues)
                                    }

                                }
                            }
                        )
                        return updatedState
                    })
                })
        }

        const interval = setInterval(() => {
            queryActuals(dateRange.currentStart)
        }, 60000)
        return () => clearInterval(interval)

    }, [activeChartData, dateRange])

    const containerProps: DualExpandableChartProps | ExpandableChartContainerProps | FlatChartContainerProps[] = props.staticContainerProps.map(containerProp => {
        if (props.addDynamicProfileSetting) {
            containerProp.addActiveCharts = (profiles) =>
                addChartsToMap(
                    profiles,
                    resolutionRef.current,
                    dateRange.currentStart,
                    dateRange.currentEnd);
            containerProp.removeCharts = removeCharts;
            containerProp.sortByExplicitRegion = (ids) => sortByExplicitRegionOrder(ids, profileToRegionMapping)
        }
        containerProp.loading = dataLoading
        containerProp.dateRange = dateRange
        containerProp.chartDataMap = activeChartData
        containerProp.chartSync = chartSync
        containerProp.triggerChartCallOnScaleChange = triggerChartCallOnScaleChange
        containerProp.mouseAction = mouseAction
        return containerProp
    })

    return (
        <Cards
            filter={
                <ExpandableSection headerText="Search" variant="container">
                    <SearchContainer {...props.searchContainerProps}/>
                </ExpandableSection>
            }
            stickyHeader={true}
            items={containerProps}
            cardDefinition={
                {
                    sections: [
                        {
                            id: "graphContainer",
                            content: props.containerDefinition
                        }
                    ]
                }
            }
            cardsPerRow={[{cards: props.cardsPerRow || 1}]}
        />)
}

function determineResolution(startTime, endTime) {
    const durationInMs = endTime.getTime() - startTime.getTime()
    if (durationInMs > 3 * 24 * 60 * 60 * 1000) {
        return 15
    } else if (durationInMs > 18 * 60 * 60 * 1000) {
        return 5
    } else {
        return 1
    }
}

function initDateRange() {
    const startTime = new Date()
    startTime.setDate(startTime.getDate() - 5)
    startTime.setTime(startTime.getTime() - 1000 * 60 * 60)
    const endTime = new Date()
    endTime.setDate(endTime.getDate() + 5)
    endTime.setTime(endTime.getTime() + 1000 * 60 * 60)
    return {
        currentStart: startTime,
        currentEnd: endTime,
        minStart: startTime,
        maxEnd: endTime
    }
}


